<template>
    <div>
        <custom-field-formula-builder
            v-if="Object.keys(availableFields).length > 0"
            :formula="field.formula"
            :type="type"
            :alt-border-color="field.saving"
            :extra-data="extraData"
            :available-fields="availableFields"
            @formula-changed="$emit('initSaveField', field)"
        />
        <hr>
        <b-form-group>
            <app-collapse type="shadow">
                <app-collapse-item title="Operators">
                    <draggable
                        :list="fields"
                        :group="{ name: 'tags', pull: 'clone', put: false}"
                        class="mb-1 cursor-move"
                    >
                        <b-badge
                            v-for="(listItem, index) in fields"
                            :key="index"
                            variant="light-info"
                            class="mr-25"
                        >
                            <div v-if="listItem.type === 'operator'">
                                <strong>{{ listItem.name }}</strong>
                            </div>
                        </b-badge>
                    </draggable>
                </app-collapse-item>
                <app-collapse-item title="Default fields">
                    <draggable
                        :list="fields"
                        :group="{ name: 'tags', pull: 'clone', put: false}"
                        class="mb-1 cursor-move"
                    >
                        <b-badge
                            v-for="(listItem, index) in fields"
                            :key="index"
                            variant="light-success"
                            class="mr-50"
                        >
                            <div v-if="listItem.badge === 'default'">
                                <strong>{{ listItem.name }}</strong>
                            </div>
                        </b-badge>
                    </draggable>
                </app-collapse-item>
                <app-collapse-item title="Linnworks fields">
                    <draggable
                        :list="fields"
                        :group="{ name: 'tags', pull: 'clone', put: false}"
                        class="mb-1 cursor-move"
                    >
                        <b-badge
                            v-for="(listItem, index) in fields"
                            :key="index"
                            variant="light-success"
                            class="mr-50"
                            :style="listItem.dataType === 'array' ? isDark ? 'border: 1px solid #6e6b7b;' : 'border: 1px solid #b4b7bd;' : ''"
                        >
                            <div v-if="listItem.badge === 'linnworks'">
                                <strong>{{ listItem.name }}</strong>
                            </div>
                        </b-badge>
                    </draggable>
                </app-collapse-item>
            </app-collapse>
        </b-form-group>
    </div>
</template>

<script>
import {
    BFormGroup, BBadge,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CustomFieldFormulaBuilder from '@/components/Project/CustomFieldFormulaBuilder'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
    components: {
        BFormGroup,
        BBadge,
        AppCollapse,
        AppCollapseItem,
        CustomFieldFormulaBuilder,
        draggable,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'inventory',
        },
        field: {
            type: Object,
            default: function() { },
        },
        availableFields: {
            type: Object,
            default: function() { },
        },
        extraData: {
            type: Object,
            default: function() { },
        },
    },
    data() {
        return {
            operators: [
                {
                    type: 'operator', name: '+', key: '+', alt: ['plus'], badge: null,
                },
                {
                    type: 'operator', name: '-', key: '-', alt: ['minus'], badge: null,
                },
                {
                    type: 'operator', name: '*', key: '*', alt: ['multiply'], badge: null,
                },
                {
                    type: 'operator', name: '/', key: '/', alt: ['divide'], badge: null,
                },
            ],
            fields: [
                {
                    type: 'operator', name: '+', key: '+', alt: ['plus'], badge: null,
                },
                {
                    type: 'operator', name: '-', key: '-', alt: ['minus'], badge: null,
                },
                {
                    type: 'operator', name: '*', key: '*', alt: ['multiply'], badge: null,
                },
                {
                    type: 'operator', name: '/', key: '/', alt: ['divide'], badge: null,
                },
            ],
        }
    },
    setup() {
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')

        return {
            isDark,
        }
    },
    created() {
        this.addLoadedDynamic()
    },
    methods: {
        addLoadedDynamic() {
            for (const field of this.availableFields[this.type]) {
                this.fields.push({
                    type: 'field',
                    name: field.label,
                    key: field.localKey,
                    alt: [field.localKey],
                    extra: field.extra ? field.extra : '',
                    dataType: field.type,
                    badge: field.group,
                })
            }
        },
    },
}
</script>

<style>

</style>