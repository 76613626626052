<template>
    <div v-if="visible" class="modal" role="dialog" style="display: block;" @click="$emit('close');">
        <div ref="modal" class="modal-dialog" role="document" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="exampleModalLabel" class="modal-title">
                        Select stock locations for field
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group m-form__group">
                        <div class="m-checkbox-list">
                            <b-container>
                                <b-row v-for="(location, index) of stockLocations" :key="index">
                                    <b-col>
                                        <template v-if="pickingMode === 'multiple'">
                                            <b-form-checkbox
                                                v-model="pickedValue"
                                                :name="location"
                                                :value="location"
                                                switch
                                            >
                                                {{ location }}
                                            </b-form-checkbox>
                                            <span />
                                        </template>
                                        <template v-if="pickingMode === 'single'">
                                            <b-form-checkbox
                                                v-model="pickedValue"
                                                :name="location"
                                                :value="location"
                                                switch
                                            >
                                                {{ location }}
                                            </b-form-checkbox>
                                        </template>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="$emit('close')">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.modal {
    background: rgba(0, 0, 0, 0.3);
}
</style>

<script>
import {
    BContainer, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'

export default {
    name: 'StockLocationsSelect',
    components: {
        BContainer,
        BRow,
        BCol,
        BFormCheckbox,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        stockLocations: {
            type: Array,
            required: true,
        },
        currentFormulaItem: {
            type: Object,
            required: true,
        },
        pickingMode: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            pickedValue: null,
        }
    },
    watch: {
        visible(newValue) {
            if (newValue) {
                this.initializeValues()
            }
        },
        pickedValue(newValue) {
            if (!newValue) {
                return
            }

            if (typeof newValue === 'string') {
                newValue = [newValue]
            }
            this.currentFormulaItem.extra.stockLocations = newValue
        },
    },
    methods: {
        initializeValues() {
            if (this.pickingMode === 'single') {
                [this.pickedValue] = this.currentFormulaItem.extra.stockLocations
            }
            if (this.pickingMode === 'multiple') {
                this.pickedValue = this.currentFormulaItem.extra.stockLocations
            }
        },
        closeDropdown() {
            this.visible = false
        },
        onEscapeKeyUp(event) {
            if (event.which === 27) {
                this.$emit('close')
            }
        },
    }
}
</script>
