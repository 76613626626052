<template>
    <div>
        <div class="d-flex justify-content-center">
            <b-spinner
                v-if="loading"
                variant="primary"
            />
        </div>
        <div v-if="!loading">
            <b-container fluid>
                <b-row>
                    <b-col cols="2" class="text-center">
                        Field name
                    </b-col>
                    <b-col cols="8" class="text-center">
                        Field formula
                    </b-col>
                    <b-col cols="2" class="text-center">
                        Formula actions
                    </b-col>
                </b-row>
                <hr>
                <div
                    v-for="(field, index) in fields"
                    :key="index"    
                >
                    <b-overlay
                        :show="field.saving"
                        variant="light"
                        opacity="0.30"
                    >
                        <b-row class="mb-1">
                            <b-col cols="2">
                                <b-input-group
                                    :prepend="String(index + 1)"
                                >
                                    <b-form-input v-model="field.name" placeholder="Field name" />
                                </b-input-group>
                            </b-col>
                            <b-col cols="8">
                                <custom-field-formula-builder
                                    v-if="Object.keys(availableFields).length > 0"
                                    :formula="field.formula"
                                    :type="type"
                                    :alt-border-color="field.saving"
                                    :extra-data="extraData"
                                    :available-fields="availableFields"
                                    @formula-changed="initSaveField(field)"
                                />
                            </b-col>
                            <b-col cols="2" class="text-center">
                                <b-button-group>
                                    <b-button
                                        v-b-tooltip.hover title="Custom field formula builder"
                                        variant="success"
                                        size="sm"
                                        @click="fieldEditor(field)"
                                    >
                                        <feather-icon icon="EditIcon" />
                                    </b-button>
                                    <b-button
                                        v-b-tooltip.hover title="Process the field formula"
                                        variant="primary"
                                        style="width: 110px;"
                                        size="sm"
                                        :disabled="field.status === 2 || !field.name"
                                        @click="recalculateValues(field)"
                                    >
                                        <template v-if="!field.status">
                                            Calculate
                                        </template>
                                        <template v-if="field.status === 1">
                                            Recalculate
                                        </template>
                                        <template v-if="field.status === 2">
                                            Calculating
                                        </template>
                                    </b-button>
                                    <b-button
                                        v-b-modal.confirmModal
                                        v-b-tooltip.hover title="Delete the custom field"
                                        variant="danger"
                                        size="sm"
                                        @click="deleteFieldID(field)"
                                    >
                                        <feather-icon icon="ArchiveIcon" />
                                    </b-button>
                                </b-button-group>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </div>
            </b-container>
            <hr>
            <b-button
                variant="success"
                class="mr-2"
                @click="addEmptyItem"
            >
                Add item
            </b-button>
        </div>
        <modal-confirm-vuexy
            text="Are you sure you want to remove this custom fields? Make sure it is removed from all the layouts first."
            title="Remove custom field?"
            :ok-function="deleteField"
            :cancel-function="deleteFieldModalClosed"
        />
        <b-modal
            v-model="isEditModalVisible"
            size="lg"
            ok-only
            ok-title="Close"
            title="Custom field formula editor"
            @ok="saveField(fieldToEdit)"
        >
            <formula-builder-modal
                :available-fields="availableFields"
                :field="fieldToEdit"
                :type="type"
                :extra-data="extraData"
            />
        </b-modal>
    </div>
</template>

<style>
</style>
<script>
import Axios from 'axios'
import ModalConfirmVuexy from '@/components/Modals/ModalConfirmVuexy.vue'
import CustomFieldFormulaBuilder from '@/components/Project/CustomFieldFormulaBuilder.vue'
import FormulaBuilderModal from '@/components/Modals/FormulaBuilderModal'
import {
    BSpinner,
    BContainer,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BButton,
    BButtonGroup,
    BModal,
    BOverlay,
    VBTooltip,
} from 'bootstrap-vue'

export default {
    name: 'settings.customFields',
    components: {
        ModalConfirmVuexy,
        CustomFieldFormulaBuilder,
        FormulaBuilderModal,
        BSpinner,
        BContainer,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BButton,
        BButtonGroup,
        BModal,
        BOverlay,
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            availableFields: {},
            fields: [],
            loading: false,
            fieldIdToDelete: 0,
            extraData: {},
            fieldToEdit: 0,
            isEditModalVisible: false,
        }
    },
    created() {
        this.loadFields()
    },
    methods: {
        loadFields() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}custom-fields/list`,
                data: {
                    type: this.type,
                },
                method: 'POST',
            })
                .then(resp => {
                    this.loading = false
                    this.fields = resp.data.customFields.map(field => {
                        field.saving = false
                        field.saveTimeout = null
                        field.errors = []
                        field.formula = JSON.parse(field.formula)

                        return field
                    })
                    this.extraData = resp.data.extraData
                    this.availableFields = resp.data.availableFields
                })
                .catch(() => {
                    this.loading = false
                    this.fields = []
                    this.availableFields = {}
                })
        },
        addEmptyItem() {
            const tmpItem = {
                id: 0,
                saving: false,
                saveTimeout: null,
                errors: [],
                name: '',
                formula: [],
                status: 0,
            }

            this.fields.push(tmpItem)
        },
        fieldEditor(field) {
            this.fieldToEdit = field
            this.isEditModalVisible = true
        },
        deleteFieldID(item) {
            this.fieldIdToDelete = item.id
        },
        deleteField() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}custom-fields/delete`,
                data: {
                    type: this.type,
                    id: this.fieldIdToDelete,
                },
                method: 'DELETE',
            })
                .finally(() => {
                    this.fieldIdToDelete = 0
                    this.deleteFieldModalOpen = false
                    this.loadFields()
                })
        },
        async recalculateValues(field) {
            field.status = 2
            if (!field.id) {
                await this.saveField(field)
            }
            await Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}custom-fields/cache-values`,
                data: {
                    type: this.type,
                    id: field.id,
                },
                method: 'POST',
            })
        },
        deleteFieldModalClosed() {
            this.fieldIdToDelete = 0
            this.deleteFieldModalOpen = false
        },
        initSaveField(field) {
            clearTimeout(field.saveTimeout)
            field.saveTimeout = setTimeout(() => {
                this.saveField(field)
            }, 3000)
        },
        async saveField(field) {
            field.saving = true
            field.errors = []
            if (this.validateField(field)) {
                const action = field.id === 0 ? 'create' : 'update'
                const resp = await Axios({
                    url: `${process.env.VUE_APP_API_HTTP_ROOT}custom-fields/${action}`,
                    data: {
                        type: this.type,
                        name: field.name,
                        id: field.id,
                        formula: field.formula,
                    },
                    method: 'POST',
                })
                field.id = resp.data.id
                field.saving = false
            }
        },
        validateField(field) {
            if (field.name.length === 0) {
                field.errors.push('Name is required')
            }

            return field.errors.length === 0
        },
        // Was told to comment this for the current state
        // As Janis thought we don't need it now, but who knows, he said, maybe we will want it later.
        // addAllFields() {
        //     for (const field of this.availableFields[this.type]) {
        //         if (field.group !== 'linnworks') {
        //             continue
        //         }
        //         const tmpField = {
        //             id: 0,
        //             saving: false,
        //             saveTimeout: null,
        //             errors: [],
        //             name: field.label,
        //             formula: [
        //                 {
        //                     type: 'field',
        //                     fieldKey: field.localKey,
        //                     name: field.label,
        //                     dataType: 'string',
        //                     extra: {},
        //                 },
        //             ],
        //         }
        //         this.fields.push(tmpField)
        //         this.saveField(tmpField)
        //     }
        // },
    },
}
</script>
