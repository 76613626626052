<template>
    <div class="formula-builder_wrapper">
        <div
            :class="['form-control', 'formula-builder_main-input', altBorderColor ? 'formula-builder_main-input--border-alt' : '', isDark ? 'formula-builder_main-input_dark' : '']"
        >
            <draggable
                :list="formula"
                group="tags"
                class="formula-builder_list cursor-move"
                @change="addItemFromAutocompleteWrapper"
            >
                <span
                    v-for="(formulaItem, index) of formula"
                    :key="index"
                    class="formula-builder_item"
                >
                    <b-badge
                        v-if="formulaItem.type === 'field'"
                        v-b-tooltip.hover :title="'fieldKey' in formulaItem ? (formulaItem.fieldKey.includes('linnworks') ? 'Linnworks' : 'Default') : ''"
                        variant="light-success"
                        :class="[Object.keys(formulaItem.extra).length > 0 ? (isDark ? 'formula-builder_item-tag--actionable_dark' : 'formula-builder_item-tag--actionable_light') : '']"
                    >
                        <a
                            v-if="Object.keys(formulaItem.extra).length > 0"
                            href="javascript:"
                            style="color: #28c76f !important;"
                            @click="handleExtraDataSelect(formulaItem)"
                        >
                            <strong>{{ formulaItem.name }}</strong>
                        </a>
                        <span v-else>
                            <strong>{{ formulaItem.name }}</strong>
                        </span>
                        <a href="javascript:" class="formula-builder_remove-item-link" @click="removeFormulaItem(formulaItem)">
                            <feather-icon
                                icon="XIcon"
                                class="text-success"
                            />
                        </a>
                    </b-badge>
                    <b-badge v-else-if="formulaItem.type === 'operator'" v-b-tooltip.hover title="Operator" variant="light-info">
                        <strong>{{ formulaItem.operator }}</strong>
                        <a href="javascript:" class="formula-builder_remove-item-link" @click="removeFormulaItem(formulaItem)">
                            <feather-icon
                                icon="XIcon"
                                class="text-info"
                            />
                        </a>
                    </b-badge>
                    <b-badge v-else-if="formulaItem.type === 'concat'" v-b-tooltip.hover title="Separator" variant="light-warning">
                        <strong>{{ formulaItem.string }}</strong>
                        <a href="javascript:" class="formula-builder_remove-item-link" @click="removeFormulaItem(formulaItem)">
                            <feather-icon
                                icon="XIcon"
                                class="text-warning"
                            />
                        </a>
                    </b-badge>
                </span>
            </draggable>
            <input ref="formula-builder_input" v-model="newItemText" v-on-clickaway="hideAutocomplete" placeholder="Type here..." :class="['formula-builder_input', isDark ? 'formula-builder_input-dark' : 'formula-builder_input-light']" @keypress.enter="addConcatString()" @focus="focusingInput = true">
        </div>
        <div v-if="autocompleteVisible" class="formula-builder_autocomplete">
            <ul :class="[isDark ? 'formula-builder_autocomplete-list_dark' : 'formula-builder_autocomplete-list_light']">
                <li v-for="(item, index) of showAutocompleteItems" :key="index"
                    class="formula-builder_autocomplete-item" @click="addItemFromAutocomplete(item)"
                >
                    <strong>{{ item.name }}</strong>
                    <template v-if="item.badge">
                        <b-badge v-if="item.badge === 'linnworks'" variant="light-warning">
                            Linnworks
                        </b-badge>
                        <b-badge v-if="item.badge === 'default'" variant="light-info">
                            Default
                        </b-badge>
                    </template>
                </li>
            </ul>
        </div>
        <b-alert
            :show="errors.length > 0"
            class="mt-2"
            variant="danger"
        >
            <li
                v-for="(error, index) of errors"
                :key="index"
                class="ml-1"
            >
                {{ error }}
            </li>
        </b-alert>
        <stock-locations-select
            :visible="stockLocationModalVisible"
            :current-formula-item="stockLocationsPickingItem"
            :stock-locations="extraData.stockLocations"
            :picking-mode="stockLocationPickingMode"
            @close="closeStockLocationPickModal"
        />
    </div>
</template>

<script>
import StockLocationsSelect from '@/components/Modals/StockLocationsSelect.vue'
import { mixin as clickaway } from 'vue-clickaway'
import {
    BBadge,
    BAlert,
    VBTooltip,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
    name: 'settings.customFields.formulaBuilder',
    components: {
        StockLocationsSelect,
        BBadge,
        BAlert,
        draggable,
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    mixins: [
        clickaway,
    ],
    props: {
        formula: {
            type: Array,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        extraData: {
            type: Object,
            required: true,
        },
        availableFields: {
            type: Object,
            required: true,
        },
        altBorderColor: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            focusingInput: false,
            newItemText: '',
            autocompleteOptions: [
                {
                    type: 'operator', name: '+', key: '+', alt: ['plus'], badge: null,
                },
                {
                    type: 'operator', name: '-', key: '-', alt: ['minus'], badge: null,
                },
                {
                    type: 'operator', name: '*', key: '*', alt: ['multiply'], badge: null,
                },
                {
                    type: 'operator', name: '/', key: '/', alt: ['divide'], badge: null,
                },
            ],
            errors: [],
            stockLocationModalVisible: false,
            stockLocationPickingMode: 'multiple',
            stockLocationsPickingItem: {},
        }
    },
    setup() {
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')

        return {
            isDark,
        }
    },
    computed: {
        showAutocompleteItems() {
            const searchString = this.newItemText.trim().toLowerCase()
            return this.autocompleteOptions.filter(item => {
                const stringsToCheck = item.alt
                stringsToCheck.push(item.name)

                for (const string of stringsToCheck) {
                    if (string.toLowerCase().indexOf(searchString) !== -1) {
                        return true
                    }
                }

                return false
            })
        },
        autocompleteVisible() {
            return this.focusingInput
            // return this.newItemText !== '' && this.showAutocompleteItems.length > 0;
        },
        formulaHasOperators() {
            return this.formula.filter(item => item.type === 'operator').length > 0
        },
        formulaHasConcat() {
            return this.formula.filter(item => item.type === 'concat').length > 0
        },
    },
    created() {
        this.loadValidFields()
    },
    methods: {
        addItemFromAutocompleteWrapper(newIndex, element) {
            if ('added' in newIndex) {
                this.formula.slice(-1)
                this.addItemFromAutocomplete(newIndex.added.element)
            }
            else if ('moved' in newIndex) {
                this.updateFormula()
            }
        },
        loadValidFields() {
            this.addLoadedDynamic()
        },
        addItemFromAutocomplete(item) {
            this.newItemText = ''

            let itemToPush = {}
            if (item.type === 'field') {
                if ('key' in item) {
                    itemToPush = {
                        type: 'field',
                        fieldKey: item.key,
                        name: item.name,
                        dataType: item.dataType,
                        extra: {},
                    }
                }
                else {
                    itemToPush = {
                        type: 'field',
                        fieldKey: item.fieldKey,
                        name: item.name,
                        dataType: item.dataType,
                        extra: {},
                    }
                }
                if (item.extra && item.extra === 'pickStockLocation') {
                    itemToPush.extra.stockLocations = []
                    itemToPush.extra.stockLocationPickingMode = 'multiple'
                }
                if (item.extra && item.extra === 'pickSingleStockLocation') {
                    itemToPush.extra.stockLocations = []
                    itemToPush.extra.stockLocationPickingMode = 'single'
                }
            }
            if (item.type === 'operator') {
                itemToPush = {
                    type: 'operator',
                    operator: item.key,
                }
            }
            this.formula.push(itemToPush)

            // A very nasty workaround for 'replace' bug that comes with draggable
            // They promised to fix it..at some point? (-.-)
            // it basically does not get identified, since it loses core variables,
            // due to the fact that it is being replaced, allowing only few, not important keys
            this.formula.forEach((element, index) => {
                if ('key' in element) {
                    this.formula.splice(index, 1)
                }
            })

            this.updateFormula()
            this.handleExtraDataSelect(itemToPush, item)
        },
        updateFormula() {
            if (this.isValid()) {
                this.$emit('formula-changed')
            }
        },
        addConcatString() {
            this.formula.push({
                type: 'concat',
                string: this.newItemText,
            })

            this.newItemText = ''
            this.updateFormula()
        },
        removeFormulaItem(item) {
            const index = this.formula.indexOf(item)
            this.formula.splice(index, 1)
            this.updateFormula()
        },
        isValid() {
            this.validate()
            return this.errors.length === 0
        },
        validate() {
            this.errors = []
            if (this.formulaHasConcat && this.formulaHasOperators) {
                this.errors.push('You can\'t mix concat and operators in the same default value.')
            }

            // rules for math
            // 1. all operators needs to be between 2 fields.
            // 2. all fields must be numbers (floats or integers)
            // 3. cant start or end with operators
            if (this.formulaHasOperators) {
                let i = 0
                let lastItem = null
                for (const formulaItem of this.formula) {
                    if (i === 0 && formulaItem.type === 'operator') {
                        this.errors.push('Default value can\'t start with a operator.')
                    }

                    // TODO might need to re-work the whole way how formulas work with arrays some time. It is not very eficient
                    if (formulaItem.type === 'field') {
                        if (formulaItem.dataType !== 'float' && formulaItem.dataType !== 'int' && formulaItem.dataType !== 'array') {
                            this.errors.push('Default value can\'t have a operator and include non-numeric type field.')
                        }
                        if (lastItem === 'field') {
                            this.errors.push('Default value can\'t have two fields without an operator between them.')
                        }
                        lastItem = 'field'
                    }

                    if (formulaItem.type === 'operator') {
                        if (lastItem === 'operator') {
                            this.errors.push('Default value can\'t have two fields without an operator between them.')
                        }
                        lastItem = 'operator'
                    }
                    i += 1
                }
                if (lastItem === 'operator') {
                    this.errors.push('Default value can\'t end with a operator.')
                }
            }
        },
        handleExtraDataSelect(item) {
            if (item.extra && item.extra.stockLocations) {
                this.stockLocationsPickingItem = item
                this.stockLocationModalVisible = true
                this.stockLocationPickingMode = item.extra.stockLocationPickingMode ? item.extra.stockLocationPickingMode : 'multiple'
            }
        },
        closeStockLocationPickModal() {
            this.stockLocationsPickingItem = {}
            this.stockLocationModalVisible = false
            this.updateFormula()
        },
        hideAutocomplete() {
            this.$nextTick(() => {
                this.focusingInput = false
            })
        },
        addLoadedDynamic() {
            for (const field of this.availableFields[this.type]) {
                this.autocompleteOptions.push({
                    type: 'field',
                    name: field.label,
                    key: field.localKey,
                    alt: [field.localKey],
                    extra: field.extra ? field.extra : '',
                    dataType: field.type,
                    badge: field.group,
                })
            }
        },
    },
}
</script>

<style>
.form-control {
    background: transparent !important;
}

.formula-builder_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.formula-builder_item {
    float: left;
    margin-right: 10px;
    margin-bottom: 5px;
}

.formula-builder_input {
    border: none;
    background: transparent !important;
}

.formula-builder_input-light {
    color: #8b8895;
}

.formula-builder_input-dark {
    color: #b4b7bd;
}

.formula-builder_input:focus {
    outline: none;
}

.formula-builder_wrapper {
    position: relative;
}

.formula-builder_autocomplete {
    border: 1px solid #ced4da;
    position: absolute;
    width: 100%;
    background: white;
    max-height: 200px;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 1;
    min-width: 500px;
    right: 0;
}

.formula-builder_autocomplete-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.formula-builder_autocomplete-list_dark {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #283046 !important;
}

.formula-builder_autocomplete-list_light {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #ffffff !important;
}

.formula-builder_autocomplete-item {
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    border-bottom: 1px solid #ced4da;
    cursor: pointer;
}

.formula-builder_autocomplete-item:last-child {
    border-bottom: none;
}

.formula-builder_autocomplete-item:hover {
    background: #fafafa;
}
.formula-builder_remove-item-link {
    margin-left: 2px;
    font-weight: bold;
    font-size: 14px;
}
.formula-builder_remove-item-link:hover {
    text-decoration: none;
}
.formula-builder_main-input {
    height: auto;
    min-height: 40px;
    width: auto;
}
.formula-builder_main-input_dark {
    border: 1px solid #404656 !important;
}
.formula-builder_main-input_light {
    border: 1px solid #404656 !important;
}
.formula-builder_item-tag {
    margin-bottom: 4px;
}
.formula-builder_main-input--border-alt {
    border-color: rgb(0, 192, 168)!important;
}
.error-list {
    margin-bottom: 0;
    padding-left: 0;
}
.formula-builder_item-tag--actionable_light {
    border: 1px solid #b4b7bd;
    text-decoration: none;
}
.formula-builder_item-tag--actionable_dark {
    border: 1px solid #6e6b7b;
    text-decoration: none;
}
.formula-builder_item-tag--actionable_dark:hover {
    color: #28c76f !important;
}
.formula-builder_item-tag--actionable_light:hover {
    color: #28c76f !important;
}

</style>