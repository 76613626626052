<template>
    <div>
        <b-card>
            <b-tabs>
                <b-tab
                    v-if="isFieldFeatureEnabled('inventory')"
                    title="Inventory"
                    active
                >
                    <div id="inventory-fields-tab" :class="['tab-pane', currentTab === 'inventory' ? 'active show' : '']">
                        <custom-fields-list type="inventory" />
                    </div>
                </b-tab>
                <b-tab
                    v-if="isFieldFeatureEnabled('orders')"
                    title="Orders"
                >
                    <div id="orders-fields-tab" :class="['tab-pane', currentTab === 'orders' ? 'active show' : '']">
                        <custom-fields-list type="orders" />
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import CustomFieldsList from '@/components/Project/CustomFieldsList.vue'
import Store from '../../store'
import Features from '../../services/Features'
import {
    BTabs, BTab, BCard,
} from 'bootstrap-vue'

export default {
    name: 'settings.customFields',
    components: {
        CustomFieldsList,
        BTabs,
        BTab,
        BCard,
    },
    data() {
        return {
            currentTab: 'inventory',
        }
    },
    methods: {
        isFieldFeatureEnabled(feature) {
            const featuresLoaded = Object.keys(Store.getters.availableFeatures).length > 0
            return !!(feature && featuresLoaded && Features.checkFeature(feature))
        },
    },
}
</script>
